import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { registerReferral } from "../../app/services/ReferralForm/ReferralFormService";
import { useParams } from "react-router-dom";
import { fetchCampaignData } from "../../app/services/Campaign/campaignService";
import { Avatar, notification } from "antd";
import OverlayLoader from "../../components/OverlayLoader";

const formatPhoneNumber = (value: string) => {
  const digits = value.replace(/\D/g, ""); // Remove non-digits
  if (digits.length <= 3) {
    return digits;
  } else if (digits.length <= 6) {
    return `${digits.slice(0, 3)}-${digits.slice(3)}`;
  } else {
    return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  }
};

const isValidPhoneNumber = (phoneNumber: string) => {
  const cleaned = phoneNumber.replace(/\D/g, "");
  return cleaned.length === 10; // Must have exactly 10 digits
};

const ReferralForm = () => {
  const { id } = useParams();
  const [logoImage, setLogoImage] = useState(null);
  const [phone, setPhone] = useState<string>(""); // changed to string type for phone
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [businessName, setBusinessName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  useEffect(() => {
    const fetchData = () => {
      if (id !== undefined) {
        fetchCampaignData(0, id)
          .then((data) => {
            setBusinessName(data.business_name);
            setFirstName(data.first_name);
            setLogoImage(data.logo_image);
            if (data.message === "expired") {
              setFetchError("Referral form expired");
            }
          })
          .catch((error) => {
            setFetchError("Wrong URL");
          });
      }
    };
    fetchData();
  }, [id]);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhone(formatPhoneNumber(value)); // Apply phone number formatting
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidPhoneNumber(phone)) {
      return notification.error({
        message: "Error",
        description: "Enter a valid phone number in the format XXX-XXX-XXXX",
      });
    }

    const formDataToSend = new FormData();
    formDataToSend.append("full_name", formData.fullName);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone_number", phone);
    if (id !== undefined) {
      formDataToSend.append("cmp_emp_cli_id", id);
    }
    formDataToSend.append("message", formData.message);
    setLoading(true);

    registerReferral(formDataToSend)
      .then((response) => {
        setLoading(false);
        notification.success({
          message: "Thank you!",
          description:
            "Your information has been submitted, and someone will reach out to you shortly.",
        });

        setFormData({
          fullName: "",
          email: "",
          phoneNumber: "",
          message: "",
        });
        setPhone("");
      })
      .catch((error) => {
        setLoading(false);
        const responseData: Record<string, any> = error.response?.data || {};
        const errorMessage = responseData.error || "An unknown error occurred";
        notification.error({
          message: "Oops",
          description: errorMessage,
        });
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  if (fetchError) {
    return (
      <div className="container">
        <div className="form-wrapper m-5">
          <div style={{ textAlign: "center" }}>
            <div className="alert alert-danger" role="alert">
              <h2>Oops</h2>
              <p>{fetchError}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="form-wrapper m-5">
        <div style={{ textAlign: "center" }}>
          <h4>
            Hi! {firstName} referred you. Let’s get connected - just fill out
            the form below.
          </h4>
          {logoImage && (
            <label>
              <Avatar
                size={150}
                shape="circle"
                src={<img src={logoImage || "/assets/user.png"} alt="Logo" />}
              />
            </label>
          )}

          <h4>{businessName}</h4>
        </div>

        <Form onSubmit={handleSubmit}>
          <Form.Group className="m-3" controlId="formGridCampaignDetails">
            <Form.Label>Full name</Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              placeholder="Enter your full name"
              value={formData.fullName}
              onChange={handleChange}
              required
              maxLength={50}
            />
          </Form.Group>

          <Form.Group className="m-3" controlId="formGridReasonForSignUp">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="m-3" controlId="formGridBusinessType">
            <Form.Label>Phone number</Form.Label>
            <Form.Control
              type="text"
              value={phone}
              onChange={handlePhoneChange}
              placeholder="XXX-XXX-XXXX"
              maxLength={12} // Ensure the format length is respected
            />
          </Form.Group>

          <Form.Group className="m-3" controlId="formGridCampaignDetails">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="message"
              placeholder="Any message"
              value={formData.message}
              onChange={handleChange}
              maxLength={300}
            />
          </Form.Group>

          <Button
            variant="primary"
            className="m-3"
            type="submit"
            style={{ float: "right" }}
          >
            Submit
          </Button>
        </Form>
      </div>
      {isLoading ? <OverlayLoader /> : null}
    </div>
  );
};

export default ReferralForm;
