import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Button, Alert } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getTremendousOAuth } from "../../app/services/Reward/OAuthService";
import { setAuthorizationCode } from "../../app/features/auth/AuthSlice";
import { handleUserLogin } from "../../utils/functions/handleUserLogin";
import { LoginData } from "../Login/LoginType";

const RedirectTremendous: React.FC = () => {
  const tremendousRedirecting = Cookies.get("tremendous_redirecting") || "";
  const email = Cookies.get("user_email") || "";

  const [isCode, setIsCode] = useState(false);
  const [isError, setError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const userData: LoginData = {
    email,
    password: "dummy value",
    refreshFlag: true,
  };

  useEffect(() => {
    const handleOAuth = async (code: string) => {
      try {
        await getTremendousOAuth(code);
        await handleUserLogin(dispatch, userData);

        Cookies.remove("tremendous_redirecting");
        dispatch(setAuthorizationCode({ authorization_code: code }));
        navigate("/");
      } catch (error) {
        console.error("Error during OAuth handling:", error);
        setError(true);
      }
    };

    const code = searchParams.get("code");

    if (code) {
      setIsCode(true);
      Cookies.set("tremendous_redirecting", code);
      handleOAuth(code);
    } else if (tremendousRedirecting) {
      handleOAuth(tremendousRedirecting);
    }
  }, [searchParams, dispatch, userData, navigate]);

  const handleRedirect = () => {
    window.open(
      `https://app.tremendous.com/oauth/authorize?client_id=${process.env.REACT_APP_TREMENDOUS_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_TREMENDOUS_REDIRECT_URI}&response_type=code`,
      "_blank"
    );
  };

  return (
    <main
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh", backgroundColor: "#f8f9fa" }}
    >
      {isCode || tremendousRedirecting ? (
        isError ? (
          <div className="alert alert-danger text-center" role="alert">
            There’s a problem while creating your session. Please log in and try
            again later.
          </div>
        ) : (
          <p>Please wait while we redirect you to the dashboard page.</p>
        )
      ) : (
        <div
          className="text-center"
          style={{
            color: "#655baa",
            maxWidth: "900px",
            padding: "30px",
            background: "#ffffff",
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 31px",
            borderRadius: "10px",
          }}
        >
          <h2
            style={{
              color: "#655baa",
              marginBottom: "30px",
              fontFamily: "Arial, sans-serif",
            }}
          >
            Welcome to YesReferral!
          </h2>
          <Alert
            variant=""
            style={{
              border: "2px solid #655baa",
              borderRadius: "10px",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                lineHeight: "1.6",
                fontWeight: "bold",
              }}
            >
              Next, go to Tremendous to complete your account setup.
            </p>
            <p style={{ fontSize: "16px", lineHeight: "1.6" }}>
              We integrate with Tremendous, which is a trusted platform used by
              companies like Google and Amazon to securely issue gift card
              rewards like Visa and top-brand options.
            </p>
            <p style={{ fontSize: "16px", lineHeight: "1.6" }}>
              To connect Tremendous with your YesReferral account to send
              rewards, complete the following steps:
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "left",
              }}
            >
              <div style={{ fontSize: "16px", lineHeight: "1.6" }}>
                <div style={{ marginBottom: "8px" }}>
                  1. Create a free Tremendous account or log in to an existing
                  one.
                </div>
                <div style={{ marginBottom: "8px" }}>
                  2. Add a billing method to fund rewards.
                </div>
                <div style={{ marginBottom: "8px" }}>
                  3. Return to YesReferral to authorize the integration.
                </div>
              </div>
            </div>

            <p>
              <span style={{ fontWeight: "bold" }}>Tip: </span>&nbsp;Simply
              return to this tab or visit{" "}
              <a
                href="https://yesreferral.com/"
                style={{ color: "#655baa", textDecoration: "underline" }}
              >
                YesReferral.com
              </a>{" "}
              after finishing on Tremendous to get started!
            </p>
          </Alert>
          <Button
            variant="primary"
            style={{
              backgroundColor: "#655baa",
              border: "none",
              borderRadius: "5px",
              padding: "10px 20px",
              fontSize: "18px",
              marginBottom: "10px",
            }}
            onClick={handleRedirect}
          >
            Go To Tremendous
          </Button>
          <p>
            Already created your Tremendous account? Click &nbsp;
            <a
              href="#"
              onClick={handleRedirect}
              style={{
                color: "#655baa",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              Authorize Tremendous &nbsp;
            </a>
            to finalize your connection.
          </p>
        </div>
      )}
    </main>
  );
};

export default RedirectTremendous;
