export const BACKEND_URL =
    process.env.REACT_APP_ENV === "dev"
        ? process.env.REACT_APP_DEV_URL
        : process.env.REACT_APP_PROD_URL;
export const API_BASE_URL = BACKEND_URL + "/api/";

export const SUBSCRIPTION_API = {
    CREATE_CHECKOUT_SESSION: API_BASE_URL + "stripe/create-checkout-session/",
    FETCH_PAYMENT_DATA: API_BASE_URL + "stripe/payment-data/:user_id/",
    CANCEL_SUBSCRIPTION: API_BASE_URL + "stripe/subscription/cancel/:user_id/"
};
export  const USER_REGISTRATION_LOGIN_API = {
    LOGIN : BACKEND_URL +  "/auth/user/login",
    REGISTER : BACKEND_URL +  "/auth/user/register",
    EMAIL_VARIFY : BACKEND_URL +  "/auth/user/email/verify",
    FORGOT_PASSWORD : BACKEND_URL +  "/auth/user/forgot-password",
    RESET_PASSWORD : BACKEND_URL +  "/auth/user/forgot-password",
    PROFILE : API_BASE_URL + "user/put/",
    FETCH_REFRESH_CODE : BACKEND_URL +  "/auth/user/token/refresh",
    LOGOUT_USER : BACKEND_URL + "/auth/user/logout"
}

export const CLIENTEMPLOYEE_API = {
    UPLOAD_CSV : API_BASE_URL + "clientsEmployees/addClientsEmployees/upload/csv/",
    MANUAL_ADD : API_BASE_URL + "clientsEmployees/addClientsEmployees/manual/",
    GET_EMPLOYEECLIENT_BY_EMPCLIID : API_BASE_URL + "clientsEmployees/getClientsEmployees/",
    GET_EMPLOYEECLIENT_BY_USERID : API_BASE_URL + "clientsEmployees/getClientsEmployees/",
    DELETE_EMPLOYEECLIENT : API_BASE_URL + "clientsEmployees/delete/",
    EDIT_EMPLOYEECLIENT : API_BASE_URL + "clientsEmployees/put/",
    GET_EMPLOYEESCLIENTS : API_BASE_URL + "clientsEmployees/get/",
}

export const CAMPAIGN_API = {
    CREATE_CAMPAIGN : API_BASE_URL + "campaign/post",
    FETCH_CAMPAIGN_DATA :  API_BASE_URL + "campaign/get/:user_id/",
    FETCH_CAMPAIGN_DATA_REFERRAL :  API_BASE_URL + "referral-form/get/:id",
    DELETE_CAMPAIGN_REFERRAL : API_BASE_URL + "campaign/delete/:referred_id",
    CHECK_CAMPAIGN_NAME : API_BASE_URL + "campaign/check-campaign-name/"
}

export const REFERRAL_API = {
    REFFERAL_FORM : BACKEND_URL +  "/referral-form/post",
}

export const REWARD_API = {
    GET_TRE_OAUTH : API_BASE_URL + "rewards/callback/post/:user_id/:code",
    SEND_REWARD : API_BASE_URL + "rewards/send-reward",
    DENY_REWARD : API_BASE_URL + "rewards/deny-referral/:referral_id"
}

export const REWARD_DASHBOARD = {
    REWARD_HISTORY : API_BASE_URL + "rewards/reward-history/:user_id",
    FETCH_BALANCE_DETAILS : API_BASE_URL + "rewards/tremendous/balance/:user_id",
}