import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import FetchedEmployeesClients from "./FetchedEmployeesClients";
import { fetchDataByUserId } from "../../app/services/EmployeesClients";
import {
  createCampaign,
  checkCampaignName,
} from "../../app/services/Campaign/campaignService";
import dayjs, { Dayjs } from "dayjs";
import { notification } from "antd";
import Cookies from "js-cookie";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { DatePicker } from "antd";
import type { DatePickerProps } from "antd";
import OverlayLoader from "../../components/OverlayLoader";
import { InputGroup } from "react-bootstrap";
dayjs.extend(isSameOrBefore);
function CreateCampaign() {
  // const { user } = useSelector((state: RootState) => state.auth);
  const userId = parseInt(Cookies.get("user_id") || "0", 10);
  const [campaignData, setCampaignData] = useState({
    reward_amount: "",
    campaign_guidelines:
      "1. When the Referred person has (made a purchase/signed a contract/used service for a certain period of time, etc). you will receive the referral reward.\n2. Offer not valid for self-referrals.\n3. The Referred person can not be an existing (employee/client/customer).\n4. Referred person must use your link in order to qualify for the reward.",
    campaign_end_date: null as Dayjs | null | undefined,
    job_opening_details: "",
    campaign_type: "",
    campaign_name: "",
  });
  const [userData, setUserData] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isCreateCampaignLoading, setIsCreateCampaignLoading] = useState(false);
  useEffect(() => {
    if (campaignData.campaign_type !== "") {
      // eslint-disable-next-line
      fetchData();
    }
    // eslint-disable-next-line
  }, [campaignData.campaign_type]);
  useEffect(() => {
    if (
      campaignData.campaign_end_date &&
      dayjs(campaignData.campaign_end_date).isSameOrBefore(dayjs(), "day")
    ) {
      notification.error({
        message: "Error",
        description: "Campaign end date cannot be in the past.",
      });
      setCampaignData((prevData) => ({
        ...prevData,
        campaign_end_date: null,
      }));
    }
  }, [campaignData.campaign_end_date]);
  useEffect(() => {
    const campaignName = campaignData.campaign_name;
    if (campaignName) {
      checkCampaignName(campaignName)
        .then((data) => {
          if (data.exists) {
            notification.error({
              message: "Error",
              description:
                "This campaign name is already in use. Please choose a different name.",
            });
            setIsNameValid(false);
          } else {
            setIsNameValid(true);
          }
        })
        .catch((errorMessage) => {
          console.error("Error checking campaign name:", errorMessage);
        });
    }
  }, [campaignData.campaign_name]);
  const fetchData = async () => {
    try {
      const data = await fetchDataByUserId(
        userId,
        0,
        campaignData.campaign_type
      );
      setUserData(data);
      setSelectedUsers([]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    if (name === "reward_amount") {
      const numberValue = parseInt(value, 10);
      if (numberValue < 1 || numberValue > 2000) {
        notification.error({
          message: "Error",
          description: "Reward amount must be between 1 and 2000.",
        });
        return;
      }
    }
    const parsedValue = name === "campaign_end_date" ? dayjs(value) : value;
    setCampaignData((prevData) => ({
      ...prevData,
      [name]: parsedValue,
    }));
  };
  const handleUserSelect = (userIds: number[]) => {
    setSelectedUsers(userIds);
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formattedEndDate = campaignData.campaign_end_date
      ? campaignData.campaign_end_date
      : null;
    const campaignEndDate = formattedEndDate
      ? dayjs(formattedEndDate).format("YYYY-MM-DD")
      : null;
    const requestData = {
      ...campaignData,
      user_id: userId,
      campaign_end_date: campaignEndDate,
      selected_users: selectedUsers,
    };
    if (
      !requestData.selected_users ||
      requestData.selected_users.length === 0
    ) {
      notification.error({
        message: "Error",
        description: "Select at least one employee/client.",
      });
      return;
    }
    if (campaignData.campaign_type === "Employee") {
      requestData.job_opening_details = campaignData.job_opening_details;
    }
    setIsCreateCampaignLoading(true);
    createCampaign(requestData)
      .then(() => {
        notification.success({
          message: "Success",
          description: "Campaign created successfully!",
        });
        setCampaignData({
          reward_amount: "",
          campaign_guidelines:
            "1. When the Referred person has (made a purchase/signed a contract/used service for a certain period of time, etc). you will receive the referral reward.\n2. Offer not valid for self-referrals.\n3. The Referred person can not be an existing (employee/client/customer).\n4. Referred person must use your link in order to qualify for the reward.",
          campaign_end_date: null,
          job_opening_details: "",
          campaign_type: "",
          campaign_name: "",
        });
        setSelectedUsers([]);
        setIsCreateCampaignLoading(false);
      })
      .catch((error) => {
        setIsCreateCampaignLoading(false);
        let errorMessage;
        if (error.statusCode === 422) {
          errorMessage =
            "Looks like this campaign name is already created.\nTry again with another campaign name";
        } else {
          errorMessage = typeof error === "string" ? error : "Please try again";
        }
        notification.error({
          message: "Error",
          description: errorMessage,
        });
      });
  };
  const dateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setCampaignData((prevData) => ({
      ...prevData,
      // eslint-disable-next-line
      ["campaign_end_date"]: date,
    }));
  };
  return (
    <div className="container pb-5">
      <div
        className="form-wrapper"
        style={{ borderBottom: "1px solid rgb(212, 196, 219)" }}
      >
        <h2 className="mb-3">Create Campaign</h2>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Form.Group
              as={Col}
              controlId="formGridCampaignName"
            >
              <Form.Label style={{ margin: "10px 0 10px 0" }}>Campaign Name</Form.Label>
              <Form.Control
                type="text"
                name="campaign_name"
                placeholder="Enter a campaign name"
                value={campaignData.campaign_name}
                onChange={handleChange}
                required
                maxLength={30}
              />
              {!isNameValid && (
                <p className="mt-3" style={{ color: "red" }}>
                  This campaign name is already in use. Please choose a
                  different name.
                </p>
              )}
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridCampaignType"
            >
              <Form.Label style={{ margin: "10px 0 10px 0" }}>Campaign type</Form.Label>
              <span style={{ color: "#655baa" }}>
                {" "}
                (Are you seeking new clients or new employees?)
              </span>
              <select
                id="campaignTypeSelect"
                className="form-select"
                name="campaign_type"
                onChange={handleChange}
                required
              >
                <option value="">Select Campaign type</option>
                <option value="Client">Client</option>
                <option value="Employee">Employee</option>
              </select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              as={Col}
              controlId="formGridRewardAmount"
            >
              <Form.Label style={{ margin: "20px 0 10px 0" }}>Reward amount</Form.Label>
              <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  name="reward_amount"
                  placeholder=""
                  value={campaignData.reward_amount}
                  onChange={handleChange}
                  min="1"
                  max="2000"
                  title="Please enter a number between 1 and 2000"
                  required
                />
              </InputGroup>
            </Form.Group>
            <Form.Group
              as={Col}
              controlId="formGridCampaignEndDate"
            >
              <Form.Label style={{ margin: "20px 0 10px 0" }}>Campaign end date</Form.Label>
              <div>
                <DatePicker
                  format={{
                    format: "MM-DD-YYYY",
                    type: "mask",
                  }}
                  placeholder="MM-DD-YYYY"
                  size="large"
                  onChange={dateChange}
                  value={campaignData.campaign_end_date}
                  style={{ width: "100%" }}
                  required
                />
              </div>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              as={Col}
              controlId="formGridCampaignGuidelines"
            >
              <Form.Label style={{ margin: "20px 0 10px 0" }}>Create campaign guidelines</Form.Label>
              <span style={{ color: "#655baa" }}>
                {" "}
                (Enter any rules or regulations you have for your campaign. Edit
                text below as needed.)
              </span>
              <Form.Control
                as="textarea"
                rows={8}
                name="campaign_guidelines"
                placeholder="Enter the guidelines"
                value={campaignData.campaign_guidelines}
                onChange={handleChange}
                required
                maxLength={600}
              />
            </Form.Group>
            {campaignData.campaign_type === "Employee" && (
              <Form.Group
                as={Col}
                controlId="formGridJobOpeningDetails"
              >
                <Form.Label style={{ margin: "20px 0 10px 0" }}>Job opening details </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="job_opening_details"
                  placeholder="Briefly share with your employees the job openings you have available so they may share this with others."
                  value={campaignData.job_opening_details}
                  onChange={handleChange}
                  required
                  maxLength={500}
                />
              </Form.Group>
            )}
          </Row>

          {campaignData.campaign_type && (
            <FetchedEmployeesClients
              userData={userData}
              selectedUsers={selectedUsers}
              handleUserSelect={handleUserSelect}
              campaign_type={campaignData.campaign_type}
            />
          )}
          {isCreateCampaignLoading ? <OverlayLoader /> : <></>}
          <Row>
            <Col>
              <Button
                variant="primary"
                className="m-3 float-end"
                type="submit"
                disabled={!isNameValid}
              >
                Create campaign
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
export default CreateCampaign;
