import { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { UserData } from './ClientsEmployees';
import { Form, Button, Card } from 'react-bootstrap';
import { editEmployeeClient } from '../../app/services/EmployeesClients';
import '../../style/common.css';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import OverlayLoader from '../../components/OverlayLoader';

const EditClientEmployee = () => {
  const location = useLocation();
  const userData = location.state?.userData as UserData[];
  const [editedData, setEditedData] = useState<UserData[]>(userData);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    id: number,
    editedUserData: UserData
  ) => {
    event.preventDefault();
    const formattedPhone = editedUserData.phone_number.replace(/\D/g, '');
    const phoneRegex = /^[2-9]{1}[0-9]{2}[2-9]{1}[0-9]{2}[0-9]{4}$/;
    if (formattedPhone.length !== 10 || !phoneRegex.test(formattedPhone)) {
      return notification.error({
        message: 'Error',
        description: 'Please enter a valid phone number in the format XXX-XXX-XXXX',
      });
    }
  
    try {
      setIsLoading(true);
      await editEmployeeClient(id, editedUserData);
      setIsLoading(false);
      navigate(`/addClientsEmployees`);
    } catch (error: any) {
      setIsLoading(false);
      return notification.error({
        message: 'Error',
        description: 'Error updating data: ' + error.message,
      });
    }
  };
  

  const handlePhoneChange = (index: number, phone: string) => {
    const cleanedPhone = phone.replace(/\D/g, '');
    let formattedPhone = '';
    if (cleanedPhone.length <= 3) {
      formattedPhone = cleanedPhone;
    } else if (cleanedPhone.length <= 6) {
      formattedPhone = `${cleanedPhone.slice(0, 3)}-${cleanedPhone.slice(3)}`;
    } else {
      formattedPhone = `${cleanedPhone.slice(0, 3)}-${cleanedPhone.slice(3, 6)}-${cleanedPhone.slice(6, 10)}`;
    }

    const updatedData = [...editedData];
    updatedData[index] = { ...updatedData[index], phone_number: formattedPhone };
    setEditedData(updatedData);
  };

  const handleInputChange = (index: number, field: string, value: string) => {
    const updatedData = [...editedData];
    updatedData[index] = { ...updatedData[index], [field]: value };
    setEditedData(updatedData);
  };

  return (
    <div className="edit-page-container">
      <h1>Edit Client or Employee</h1>
      <div className="edit-content">
        <div className="edit-form-container">
          {userData.map((user, index) => (
            <Card
              key={index}
              className="edit-card"
              style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
            >
              <Card.Body>
                <Form onSubmit={(event) => handleSubmit(event, user.id, editedData[index])}>
                  <Form.Group
                    controlId={`formFirstName-${index}`}
                    className="pb-4"
                    style={{ fontSize: '1rem', fontWeight: 'bold' }}
                  >
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      maxLength={30}
                      value={editedData[index].first_name} // Controlled by state
                      onChange={(e) => handleInputChange(index, 'first_name', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    controlId={`formLastName-${index}`}
                    className="pb-4"
                    style={{ fontSize: '1rem', fontWeight: 'bold' }}
                  >
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      maxLength={30}
                      value={editedData[index].last_name}
                      onChange={(e) => handleInputChange(index, 'last_name', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    controlId={`formEmail-${index}`}
                    className="pb-4"
                    style={{ fontSize: '1rem', fontWeight: 'bold' }}
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      required
                      value={editedData[index].email}
                      onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    controlId={`formPhoneNumber-${index}`}
                    className="pb-4"
                    style={{ fontSize: '1rem', fontWeight: 'bold' }}
                  >
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="XXX-XXX-XXXX"
                      value={editedData[index].phone_number}
                      maxLength={12}
                      onChange={(e) => handlePhoneChange(index, e.target.value)}
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit" style={{ marginRight: '10px' }}>
                    Save
                  </Button>
                  <Link
                    to={`/addClientsEmployees`}
                    className="btn btn-secondary"
                    style={{ marginLeft: '10px', float: 'right' }}
                  >
                    Back
                  </Link>
                </Form>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>
      {isLoading ? <OverlayLoader /> : <></>}
    </div>
  );
};

export default EditClientEmployee;
