import React, { useState, useEffect } from "react";
import {
  uploadCSV,
  fetchDataByUserId,
  deleteEmployeeClient,
  manualUpload,
} from "../../app/services/EmployeesClients";
import { message, notification, Popconfirm } from "antd";
import {
  UserSwitchOutlined,
  IdcardOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Modal, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { Container, Row, Table, Form } from "react-bootstrap";
import "../../style/common.css";
import Cookies from "js-cookie";
import "./AddClientsEmployees.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import OverlayLoader from "../../components/OverlayLoader";
import { Typography } from "antd";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDropzone } from "react-dropzone";

const AddClientsEmployees = () => {
  const userId = parseInt(Cookies.get("user_id") || "0", 10);
  const [file, setFile] = useState<File | null>(null);
  const [userData, setUserData] = useState<any[]>([]);
  const [type, setType] = useState<"Client" | "Employee" | null>(null);
  const [importAllowed, setImportAllowed] = useState<boolean>(false);
  const [firstNameFilter, setFirstNameFilter] = useState<string>("");
  const [lastNameFilter, setLastNameFilter] = useState<string>("");
  const [typeFilter, setTypeFilter] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [getExampleModalVisible, setGetExampleModalVisible] = useState(false);
  const [manualEntryModalVisible, setManualEntryModalVisible] = useState(false);
  const [manualFirstName, setManualFirstName] = useState("");
  const [manualLastName, setManualLastName] = useState("");
  const [manualType, setManualType] = useState<"Client" | "Employee" | null>(
    null
  );
  const [manualEmail, setManualEmail] = useState("");
  const [manualPhoneNumber, setManualPhoneNumber] = useState<string>("");
  const navigate = useNavigate();
  const { Text } = Typography;
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    type: "",
    email: "",
    phoneNumber: "",
  });
  const [newModalVisible, setNewModalVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await fetchDataByUserId(userId, 0, "");
        setUserData(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
        notification.error({
          message: "Error",
          description: "Failed to fetch user data.",
        });
      }
    };

    fetchData();
  }, [userId, file, type]);

  const handleRemoveFile = () => {
    setFile(null);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setManualPhoneNumber(formatPhoneNumber(value)); // Format phone number as user types
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = e.target.value as "Client" | "Employee";
    setType(selectedType);
    setImportAllowed(!!selectedType);
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!type) {
      notification.error({
        message: "Error",
        description: "Please select a type before importing the file.",
      });
      return;
    }

    if (!file) {
      notification.error({
        message: "Error",
        description: "Please upload a file before importing.",
      });
      return;
    }

    try {
      setIsLoading(true);
      const successMessage = await uploadCSV(file, userId, type);
      const updatedUserData = await fetchDataByUserId(userId, 0, "");
      setIsLoading(false);
      setUserData(updatedUserData);

      notification.success({
        message: "Success",
        description: successMessage?.message || "File uploaded successfully.",
      });

      setFile(null);
      setType(null);
    } catch (error: any) {
      setIsLoading(false);
      notification.error({
        message: "Error",
        description: error.message || "Something went wrong!",
      });
    }
  };

  const isValidPhoneNumber = (phoneNumber: string) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    return cleaned.length === 10; // Must have exactly 10 digits
  };

  const formatPhoneNumber = (value: string) => {
    const digits = value.replace(/\D/g, ""); // Remove non-digits
    if (digits.length <= 3) {
      return digits;
    } else if (digits.length <= 6) {
      return `${digits.slice(0, 3)}-${digits.slice(3)}`;
    } else {
      return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(
        6,
        10
      )}`;
    }
  };

  const handleOnChange = (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles[0]) {
      setFile(acceptedFiles[0]);
    }
  };

  const handleDelete = async (emp_cli_id: number) => {
    try {
      await deleteEmployeeClient(emp_cli_id);
      const updatedUserData = await fetchDataByUserId(userId, 0, "");
      setUserData(updatedUserData);
      notification.success({
        message: "Success",
        description: "Employee or client deleted successfully.",
      });
    } catch (error) {
      console.error("Error deleting employee or client:", error);
      notification.error({
        message: "Error",
        description: "Failed to delete employee or client.",
      });
    }
  };

  const handleEdit = async (emp_cli_id: number) => {
    try {
      const updatedUserData = await fetchDataByUserId(userId, emp_cli_id, "");
      navigate(`/editClientEmployee`, { state: { userData: updatedUserData } });
    } catch (error) {
      console.error("Error in employee or client:", error);
    }
  };

  const handleFirstNameFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstNameFilter(e.target.value);
  };

  const handleLastNameFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLastNameFilter(e.target.value);
  };

  const handleTypeFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTypeFilter(e.target.value);
  };

  const filteredUserData = userData.filter(
    (user) =>
      user.first_name.toLowerCase().includes(firstNameFilter.toLowerCase()) &&
      user.last_name.toLowerCase().includes(lastNameFilter.toLowerCase()) &&
      (typeFilter === "" || user.type === typeFilter)
  );

  const downloadOnClick = () => {
    const fileUrl = process.env.PUBLIC_URL + "/assets/Sample.csv";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "Sample.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const validateForm = () => {
    const missingFields: string[] = [];
    if (!manualFirstName) missingFields.push("First Name");
    if (!manualLastName) missingFields.push("Last Name");
    if (!manualType) missingFields.push("Type");
    if (!manualEmail) missingFields.push("Email");
    if (!manualPhoneNumber) missingFields.push("Phone Number");

    if (missingFields.length > 0) {
      notification.error({
        message: "Error",
        description: `Please fill in the following fields before saving: ${missingFields.join(
          ", "
        )}.`,
      });
      return false;
    }

    if (manualPhoneNumber && !isValidPhoneNumber(manualPhoneNumber)) {
      notification.error({
        message: "Error",
        description: "Please enter a valid phone number in the format XXX-XXX-XXXX",
      });
      return false;
    }

    return true;
  };

  const handleManualEntrySubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setManualEntryModalVisible(false);
    try {
      setIsLoading(true);

      // Simulate an API call to save the data
      await manualUpload(
        manualFirstName || "",
        manualLastName || "",
        manualType || "",
        manualEmail || "",
        manualPhoneNumber || ""
      );

      // Assume that you have a fetchDataByUserId function to fetch the updated data
      const updatedUserData = await fetchDataByUserId(userId, 0, "");
      setUserData(updatedUserData);

      notification.success({
        message: "Success",
        description: "Employee or client added successfully.",
      });
      resetManualEntryFields();
    } catch (error) {
      console.error("Error adding employee or client:", error);
      notification.error({
        message: "Error",
        description: "Failed to add employee or client.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resetManualEntryFields = () => {
    setManualFirstName("");
    setManualLastName("");
    setManualType(null);
    setManualEmail("");
    setManualPhoneNumber("");
  };

  const DropzoneArea = () => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleOnChange,
      accept: {
        "text/csv": [".csv"],
        "application/vnd.ms-excel": [".xls", ".xlsx"],
        "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
      },
      multiple: false,
    });

    return (
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #d9d9d9",
          borderRadius: "5px",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
          background: isDragActive ? "#f3f3f3" : "#fff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
        }}
      >
        <input {...getInputProps()} />
        <p className="ant-upload-drag-icon" style={{ marginBottom: "10px" }}>
          <UploadOutlined style={{ fontSize: "24px" }} />
        </p>
        <p className="ant-upload-text">
          Drag and drop file here or click to upload
        </p>
      </div>
    );
  };

  return (
    <Container className="p-4">
      <h1 className="text-center my-4">Add clients/employees</h1>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: "1" }}>
          <Form.Select
            style={{ height: "40px" }}
            className="upload-type-select"
            value={type || ""}
            onChange={handleTypeChange}
          >
            <option value="">Select Type</option>
            <option value="Client">Client</option>
            <option value="Employee">Employee</option>
          </Form.Select>
        </div>

        <Row>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
          >
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                height: "40px",
                marginRight: "10px",
              }}
              onClick={() => setNewModalVisible(true)}
              type="primary"
              className="btn btn-primary"
            >
              Upload a file
            </Button>
            <Button
              style={{ display: "flex", alignItems: "center", height: "40px" }}
              type="primary"
              className="btn btn-primary"
              onClick={() => setManualEntryModalVisible(true)}
            >
              Add Manually
            </Button>
          </div>
        </Row>
      </div>

      <div className="m-2 d-flex flex-column flex-md-row align-items-start pt-3">
        <Form.Control
          type="text"
          placeholder="Search First Name"
          value={firstNameFilter}
          onChange={handleFirstNameFilterChange}
          className="mb-2 me-md-2"
        />
        <Form.Control
          type="text"
          placeholder="Search Last Name"
          value={lastNameFilter}
          onChange={handleLastNameFilterChange}
          className="mb-2 me-md-2"
        />
        <Form.Select
          value={typeFilter}
          onChange={handleTypeFilterChange}
          className="mb-2"
        >
          <option value="">Search type</option>
          <option value="Client">Client</option>
          <option value="Employee">Employee</option>
        </Form.Select>
      </div>

      {filteredUserData.length > 0 ? (
        <div className="table-wrapper">
          <Table bordered striped>
            <thead className="thead-dark">
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Type</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {filteredUserData.map((user, index) => (
                <tr key={index}>
                  <td>
                    {user.type === "Client" ? (
                      <UserSwitchOutlined />
                    ) : (
                      <IdcardOutlined />
                    )}
                  </td>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.email}</td>
                  <td>
                    {/* Format and display the phone number */}
                    {formatPhoneNumber(user?.phone_number || "")}
                  </td>
                  <td>{user.type}</td>
                  <td>
                    <Button
                      style={{ display: "flex", alignItems: "center" }}
                      type="primary"
                      className="btn btn-primary"
                      onClick={() => handleEdit(user.id)}
                    >
                      Edit
                    </Button>
                  </td>
                  <td>
                    <Popconfirm
                      title="Are you sure to delete this employee or client?"
                      onConfirm={() => handleDelete(user.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        style={{ display: "flex", alignItems: "center" }}
                        danger
                        className="red"
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <p className="mt-4">No clients or employees found.</p>
      )}
      {isLoading ? <OverlayLoader /> : <></>}

      <Modal
        title="Add Employee/Client Manually"
        open={manualEntryModalVisible}
        onCancel={() => setManualEntryModalVisible(false)}
        okText="Save"
        okButtonProps={{
          style: {
            backgroundColor: "#655baa",
            borderColor: "#655baa",
            color: "#fff",
          },
        }}
        cancelButtonProps={{
          style: {
            borderColor: "#655baa",
            color: "#655baa",
          },
        }}
        onOk={(e) => {
          e.preventDefault();
          handleManualEntrySubmit(
            e as unknown as React.FormEvent<HTMLFormElement>
          );
        }}
      >
        <Form>
          <Form.Group>
            <Form.Label htmlFor="manualFirstName">First Name</Form.Label>
            <Form.Control
              id="manualFirstName"
              value={manualFirstName}
              onChange={(e) => setManualFirstName(e.target.value)}
              isInvalid={!!errors.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="manualLastName">Last Name</Form.Label>
            <Form.Control
              id="manualLastName"
              value={manualLastName}
              onChange={(e) => setManualLastName(e.target.value)}
              isInvalid={!!errors.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="manualType">Type</Form.Label>
            <Form.Select
              id="manualType"
              className="upload-type-select"
              value={manualType || ""}
              onChange={(e) =>
                setManualType(e.target.value as "Client" | "Employee")
              }
              isInvalid={!!errors.type}
              style={{ margin: "0px 10px 0px 0px" }}
            >
              <option value="">Select Type</option>
              <option value="Client">Client</option>
              <option value="Employee">Employee</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.type}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="manualEmail">Email</Form.Label>
            <Form.Control
              id="manualEmail"
              type="email"
              value={manualEmail}
              onChange={(e) => setManualEmail(e.target.value)}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="manualPhoneNumber">Phone Number</Form.Label>
            <input
              id="manualPhoneNumber"
              type="text"
              value={manualPhoneNumber}
              onChange={handlePhoneChange} // Pass the event to the handler
              maxLength={12} // Ensure the phone number doesn't exceed the format length
              placeholder="XXX-XXX-XXXX"
              className={`form-control ${
                errors.phoneNumber ? "is-invalid" : ""
              }`}
            />
          </Form.Group>
        </Form>
      </Modal>

      <Modal
        title="Example Format"
        open={getExampleModalVisible}
        onCancel={() => setGetExampleModalVisible(false)}
        okText="Download Sample"
        cancelText="Close"
        onOk={downloadOnClick}
        okButtonProps={{
          className: "btn btn-primary",
        }}
      >
        <span style={{ color: "red" }}>
          <strong>
            Your CSV or Excel file MUST include all four column titles below, in
            any order, and MUST be written exactly as shown (example: use "Phone
            Number," instead of "Phone"). Any additional columns will be
            ignored.
          </strong>
        </span>
        <div className="mt-2 d-flex p-3 border bg-body rounded align-items-center justify-content-between">
          <p className="m-0 fw-bold">First Name</p>
          <Divider
            type="vertical"
            style={{ borderInlineStart: "1px solid #dee2e6", height: "1.5rem" }}
          />
          <p className="m-0 fw-bold">Last Name</p>
          <Divider
            type="vertical"
            style={{ borderInlineStart: "1px solid #dee2e6", height: "1.5rem" }}
          />
          <p className="m-0 fw-bold">Email</p>
          <Divider
            type="vertical"
            style={{ borderInlineStart: "1px solid #dee2e6", height: "1.5rem" }}
          />
          <p className="m-0 fw-bold">Phone Number</p>
        </div>
      </Modal>

      <Modal
        title="Upload a CSV file or Excel Sheet"
        open={newModalVisible}
        onCancel={() => setNewModalVisible(false)}
        footer={null}
      >
        Make sure the file includes{" "}
        <span style={{ color: "red", fontWeight: "bold" }}>
          "First Name", "Last Name", "Email", "Phone Number"
        </span>
        <p>
          <a
            style={{
              textDecoration: "underline",
              color: "#655baa",
              cursor: "pointer",
            }}
            onClick={() => setGetExampleModalVisible(true)}
          >
            <InfoCircleOutlined style={{ marginRight: "8px" }} />
            Example Format
          </a>
        </p>
        <Form onSubmit={handleOnSubmit} className="">
          <Row className="mb-3">
            <Form.Group>
              <div
                className="pb-3 responsive-container"
                style={{
                  height: "200px",
                  borderBottom: "1px solid rgb(212, 196, 219)",
                }}
              >
                <DropzoneArea />
              </div>
            </Form.Group>
          </Row>
          {file && (
            <Row className="mb-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "12px",
                  borderRadius: "5px",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <span>{file.name}</span>
                <Button
                  type="link"
                  danger
                  onClick={handleRemoveFile}
                  style={{ padding: 0, fontSize: "12px" }}
                >
                  Delete
                </Button>
              </div>
            </Row>
          )}
          <Row>
            <div style={{ flex: "1", marginRight: "10px" }}>
              <a
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                  marginRight: "10px",
                  textDecoration: "underline",
                  color: "#655baa",
                  cursor: "pointer",
                }}
                onClick={() => downloadOnClick()}
                className="example-link"
              >
                Download a sample file
              </a>
            </div>
            <div style={{ flex: "0" }}>
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                }}
                htmlType="submit"
                className="btn btn-primary"
              >
                Save uploaded file
              </Button>
            </div>
          </Row>
        </Form>
      </Modal>
    </Container>
  );
};

export default AddClientsEmployees;
