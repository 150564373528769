import { LoginData } from "../../pages/Login/LoginType";
import axios from "axios";
import { ForgotPasswordData } from "../../pages/ForgotPassword/ForgotPasswordType";
import { ResetPasswordData } from "../../pages/ResetPassword/ResetPasswordType";
import { USER_REGISTRATION_LOGIN_API } from "./../../utils/constants/apis.urls";
import Cookies from "js-cookie";

export const loginUser = async (data: LoginData) => {
  try {
    let res = await axios.post(USER_REGISTRATION_LOGIN_API.LOGIN, data);
    return res;
  } catch (error: any) {
    let msg = error?.response?.data?.message || error.message;
    console.log("Inside catch block error :", msg);
    return msg;
  }
};

export const registerUser = (data: FormData) => {
  return axios.post(USER_REGISTRATION_LOGIN_API.REGISTER, data)
    .then(res => res)
    .catch(error => {
      const responseData: Record<string, any> = error.response?.data || {};
      const errorMessages = Object.entries(responseData)
        .map(([field, messages]) => `${field}: ${messages[0]}`)
        .join(', ') || 'An unknown error occurred';
      console.log("responseData -- ", responseData);
      console.log("Inside catch block error: ", errorMessages);
      return { error: errorMessages };
    });
};

export const forgotPassword = async (data: ForgotPasswordData) => {
  try {
    let res = await axios.post(USER_REGISTRATION_LOGIN_API.FORGOT_PASSWORD, data);
    return res;
  } catch (error: any) {
    console.log("Inside catch block error :", error); 
    return error?.response?.data?.message || error.message;
  }
};
export const resetPassword = async (data: ResetPasswordData) => {
  try {
    let res = await axios.post(USER_REGISTRATION_LOGIN_API.RESET_PASSWORD, data);
    return res;
  } catch (error: any) {
    console.log("Inside catch block error :", error);
    return error?.response?.data?.message || error.message;
  }
};

export const userProfile = async (userId: number, editedUserData: FormData) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const res = await axios.put(USER_REGISTRATION_LOGIN_API.PROFILE + `${userId}`, editedUserData, config);
    return res.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.detail || 'An unknown error occurred';
    console.error("Inside catch block error :", errorMessage); 
    throw new Error(errorMessage);
  }
};

export const logoutUser = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    const body = {
      refresh_token: refreshToken,
    };

    const res = await axios.post(USER_REGISTRATION_LOGIN_API.LOGOUT_USER, body, config);
    
    // Clear tokens and other localStorage/sessionStorage data
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    
    Cookies.remove("user_id");
    Cookies.remove("user_email");
    Cookies.remove("userin");

    return res.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.detail || 'An unknown error occurred during logout';
    console.error("Inside catch block error:", errorMessage);
    throw new Error(errorMessage);
  }
};
