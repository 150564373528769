import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { registerUser } from '../../app/services/UserServices';
import { notification } from 'antd';
import logo from '../../images/yes-referral-logo.png';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../app/features/auth/AuthSlice';
import Cookies from 'js-cookie';
import OverlayLoader from '../../components/OverlayLoader';

const Registration = () => {
  const logoInputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [logoImage, setLogoImage] = useState<File | null>(null);
  const [newPhone, setNewPhone] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allowedTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/heif'];

  // Function to format the phone number as XXX-XXX-XXXX
  const formatPhoneNumber = (phone: string) => {
    const cleaned = phone.replace(/\D/g, ''); // Remove non-numeric characters
    let formattedPhone = '';
  
    if (cleaned.length <= 3) {
      formattedPhone = cleaned;
    } else if (cleaned.length <= 6) {
      formattedPhone = `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    } else {
      formattedPhone = `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    }
    return formattedPhone;
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setNewPhone(formattedPhone);
  };

  // Function to handle form submission
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    const register = {
      first_name: (e.target as HTMLFormElement).firstname.value,
      last_name: (e.target as HTMLFormElement).lastname.value,
      phone_number: newPhone,
      password: (e.target as HTMLFormElement).password.value,
    };

    const email = (e.target as HTMLFormElement).email.value;
    const confirmEmail = (e.target as HTMLFormElement).confirm_email.value;

    // Validate email and confirm email match
    if (email !== confirmEmail) {
      setIsLoading(false);
      return showNotification('error', 'Email and Confirm Email do not match.');
    }

    const nameRegex = /^[a-zA-Z]+$/;
    if (!nameRegex.test(register.first_name) || !nameRegex.test(register.last_name)) {
      setIsLoading(false);
      return showNotification('error', 'First name and last name should contain only letters.');
    }

    // Validate phone number
    const phoneRegex = /^[0-9]{10}$/; // Accept any 10 digits
    const formattedPhone = newPhone.replace(/\D/g, ''); // Clean phone number
    if (formattedPhone.length !== 10 || !phoneRegex.test(formattedPhone)) {
      setIsLoading(false);
      return showNotification('error', 'Please enter a valid phone number in the format XXX-XXX-XXXX');
    }

    // Validate password strength
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordRegex.test(register.password)) {
      setIsLoading(false);
      return showNotification('error', 'Password should be at least 8 characters long and include at least one number, one special character, one uppercase letter, and one lowercase letter.');
    }

    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', register.password);
    formData.append('first_name', register.first_name);
    formData.append('last_name', register.last_name);
    formData.append('phone_number', newPhone.replace(/\D/g, '')); // Clean phone number before sending
    formData.append('business_type', (e.target as HTMLFormElement).business_type.value);
    formData.append('business_name', (e.target as HTMLFormElement).business_name.value);
    if (logoImage) {
      formData.append('logo_image', logoImage);
    }

    // Send the registration request
    registerUser(formData)
      .then(async (response) => {
        if ('status' in response && response.status === 201) {
          if (e.currentTarget) {
            e.currentTarget.reset();
          }

          dispatch(loginSuccess(response.data));
          Cookies.set("subscription_redirecting", "true");
          showNotification('success', 'Your registration is successfully completed');
          navigate("/checkout-redirect");
        } else if ('error' in response) {
          setIsLoading(false);
          showNotification('error', response.error);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showNotification('error', String(error));
      });
  };

  // Function to show notifications
  const showNotification = (type: 'success' | 'error', message: string) => {
    notification[type]({
      message: type === 'success' ? 'Success' : 'Error',
      description: message,
      placement: 'topRight',
    });
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle logo image upload
  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (!file) {
      return;
    }
    if (!allowedTypes.includes(file.type)) {
      showNotification('error', 'Please upload a valid image file (JPEG, PNG, HEIC, HEIF)');
      resetFileInput();
      return;
    }
    if (file.size >= 5 * 1000 * 1024) {
      showNotification('error', 'Logo with a maximum size of 5MB is not allowed');
      resetFileInput();
      return;
    }
    setLogoImage(file);
  };

  // Reset file input after validation
  const resetFileInput = () => {
    if (logoInputRef.current) {
      logoInputRef.current.value = '';
    }
  };

  return (
    <div className="container d-flex justify-content-center py-5">
      <div style={{ width: '100%', maxWidth: '30rem', marginBottom: "10vmin" }}>
        <div className="mb-4">
          <Link to="/">
            <img className='pb-4' src={logo} alt="Logo" style={{ width: '234px', height: 'auto' }} />
          </Link>
          <h2 className="mb-4">Create your YesReferral account</h2>
        </div>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="mb-3">
            <label htmlFor="firstname" className="form-label">First name</label>
            <input type="text" className="form-control" name='firstname' id='firstname' required maxLength={30} />
          </div>
          <div className="mb-3">
            <label htmlFor="lastname" className="form-label">Last name</label>
            <input type="text" className="form-control" name='lastname' id='lastname' required maxLength={30} />
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">Phone Number</label>
            <input
              type="text"
              className="form-control"
              name="phone"
              id="phone"
              placeholder="XXX-XXX-XXXX"
              value={newPhone}
              maxLength={12}
              onChange={handlePhoneChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email Address</label>
            <input type="email" className="form-control" name="email" id="email" required />
          </div>
          <div className="mb-3">
            <label htmlFor="confirm_email" className="form-label">Confirm Email Address</label>
            <input type="email" className="form-control" name="confirm_email" id="confirm_email" required />
          </div>
          <div className="mb-3">
            <label htmlFor="business_type" className="form-label">Business type</label>
            <input type="text" className="form-control" name='business_type' id='business_type' placeholder='e.g. Home Care' required maxLength={100} />
          </div>
          <div className="mb-3">
            <label htmlFor="business_name" className="form-label">Business name</label>
            <input type="text" className="form-control" name='business_name' id='business_name' required maxLength={100} />
          </div>
          <div className="mb-3">
            <label htmlFor="logo" className="form-label">Upload Logo</label>
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.heic,.heif"
              className="form-control"
              id="logo"
              ref={logoInputRef}
              onChange={handleLogoChange} />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Create password</label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="password"
                name="password"
                required
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={togglePasswordVisibility}
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: '#007bff',
                }}>
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>
          <div className="d-grid gap-2">
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
              {isLoading ? 'Registering...' : 'Register'}
            </button>
          </div>
        </form>
      </div>
      {isLoading && <OverlayLoader />}
    </div>
  );
};

export default Registration;
