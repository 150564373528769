import axios from "axios";
import { logoutUser } from "../app/services/UserServices";

export const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        const accessToken = localStorage.getItem("accessToken");

        // const contentType = config.headers['Content-Type'];
        const contentType = null;
        config.headers = {
            ...(config.headers),
            "Content-Type": !contentType && "application/json",
            "Authorization": `${accessToken ? "Bearer " + accessToken : null}`
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // Check if the error is related to authentication (e.g., 401 Unauthorized)
        if (error.response?.status === 401) {
          console.log("Authentication error detected. Logging out...");
          
          // Call logoutUser API
          logoutUser()
            .then(() => {
              
              // Redirect to login page or perform additional actions
              window.location.href = "/user/login"; // Example redirect
            })
            .catch((logoutError) => {
              console.error("Error during logout:", logoutError.message);
            });
        }
      
        return Promise.reject(error);
      }
      
);