import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import { User, profileUpdate } from '../../../app/features/auth/AuthSlice';
import { userProfile } from '../../../app/services/UserServices';
import { notification, Avatar, Spin } from 'antd';
import Cookies from 'js-cookie';
import { LoginData } from '../../Login/LoginType';
import { handleUserLogin } from '../../../utils/functions/handleUserLogin';
import OverlayLoader from '../../../components/OverlayLoader';
import { Col, Form, Row } from 'react-bootstrap';
import { LoadingOutlined } from '@ant-design/icons';
import heic2any from 'heic2any';
import style from './style.module.scss';

const ProfileSettings = () => {
  const logoInputRef = useRef<HTMLInputElement | null>(null);
  const [logoLoading, setLogoLoading] = useState(false);
  const { user, ...userState } = useSelector((state: RootState) => state.auth);
  const [editedUser, setEditedUser] = useState<User | null>(user);
  const [isLoading, setIsLoading] = useState(false);
  const [logoImageFile, setLogoImageFile] = useState<File | null>(null);
  const [logoImageUrl, setLogoImageUrl] = useState<string | null>(null);
  const allowedTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/heif'];
  const dispatch = useDispatch();
  const email = Cookies.get('user_email') || '';
  const userData: LoginData = { email: email || '', password: 'dummy value', refreshFlag: true };

  useEffect(() => {
    const login = async () => {
      try {
        const loggedInUser = await handleUserLogin(dispatch, userData);
        setEditedUser(loggedInUser);
      } catch (error) {
        console.error('Error during login: ', error);
      }
    };
    login();
  }, [dispatch]);

  const heicToJpg = async (file: File | Blob) => {
    try {
      setLogoLoading(true);
      const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target?.result) {
          setLogoLoading(false);
          setLogoImageUrl(event.target.result as string);
        }
      };
      if (convertedBlob instanceof Blob) {
        reader.readAsDataURL(convertedBlob);
      }
    } catch (error) {
      setLogoLoading(false);
      console.error('Error converting HEIC to JPEG:', error);
    }
  };

  useEffect(() => {
    if (logoImageFile) {
      if (['image/heic', 'image/heif'].includes(logoImageFile.type)) {
        heicToJpg(logoImageFile);
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          setLogoImageUrl(reader.result as string);
        };
        reader.readAsDataURL(logoImageFile);
      }
    } else {
      setLogoImageUrl(null);
    }
  }, [logoImageFile]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedUser((prevState: User | null) => ({
      ...(prevState as User),
      [name]: value
    }));
  };

  const handlePhoneChange = (phone: string) => {
    const cleanedPhone = phone.replace(/\D/g, '');
    let formattedPhone = '';
    if (cleanedPhone.length <= 3) {
      formattedPhone = cleanedPhone;
    } else if (cleanedPhone.length <= 6) {
      formattedPhone = `${cleanedPhone.slice(0, 3)}-${cleanedPhone.slice(3)}`;
    } else {
      formattedPhone = `${cleanedPhone.slice(0, 3)}-${cleanedPhone.slice(3, 6)}-${cleanedPhone.slice(6, 10)}`;
    }
    setEditedUser((prevState) => ({
      ...prevState!,
      phone_number: formattedPhone,
    }));
  };

  const handleLogoImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (!file) {
      setLogoImageFile(null);
      setLogoImageUrl(null);
      return;
    }
    if (!allowedTypes.includes(file.type)) {
      notification.error({
        message: 'ERROR!',
        description: 'Please upload a valid image file (JPEG, PNG, HEIC, HEIF)',
      });
      resetFileInput();
      return;
    }
    if (file.size >= 5 * 1000 * 1024) {
      notification.error({
        message: 'ERROR!',
        description: 'Logo with a maximum size of 5MB is not allowed',
      });
      resetFileInput();
      return;
    }
    setLogoImageFile(file);
  };

  const resetFileInput = () => {
    if (logoInputRef.current) {
      logoInputRef.current.value = '';
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formattedPhone = editedUser?.phone_number?.replace(/\D/g, '');
    const phoneRegex = /^[2-9]{1}[0-9]{2}[2-9]{1}[0-9]{2}[0-9]{4}$/;
    if (formattedPhone?.length !== 10 || !phoneRegex.test(formattedPhone)) {
      return notification.error({
        message: 'Error',
        description: 'Please enter a valid phone number in the format XXX-XXX-XXXX',
      });
    }
    try {
      if (editedUser && editedUser.id !== undefined) {
        const formData = new FormData();
        for (const key in editedUser) {
          if (key !== 'logo_image') {
            formData.append(key, (editedUser as any)[key]);
          }
        }
        if (logoImageFile) {
          formData.append('logo_image', logoImageFile);
        }
        setIsLoading(true);
        await userProfile(editedUser.id, formData);
        setIsLoading(false);
        dispatch(profileUpdate({ ...userState, user: editedUser }));
        notification.success({
          message: 'Profile Updated',
          description: 'Your profile has been successfully updated.',
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error updating user data:', error);
    }
  };

  return (
    <div className="container">
      <h1 className="mt-5 mb-4">Profile Page</h1>
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-md-10 col-lg-2">
          <div className="p-4">
            <label className={style.profileUpload} htmlFor="uploadLogo">
              <Avatar
                size={150}
                shape="circle"

                src={
              logoLoading?
                    <Spin indicator={<LoadingOutlined spin />} size="small" />
              :logoImageUrl ? (
                <img
                  src={logoImageUrl}
                  alt="Logo"               
                />
                  ) : (
                <img
                  src={`${editedUser?.logo_image || '/assets/user.png'}`}
                  alt="Logo"
                />
                  )
            }>
              
            </Avatar>
            </label>
            <input
              type="file"
              id="uploadLogo"
              className="visually-hidden"
              accept=".jpg,.jpeg,.png,.heic,.heif"
              ref={logoInputRef}
              onChange={handleLogoImageChange}
            />
          </div>
        </div>
        <div className="col-md-10 col-lg-10">
          <Row className="justify-content-between">
            <Form.Group as={Col} md={10} lg={5} className="m-3" controlId="formFirstName">
              <Form.Label>First name</Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                value={editedUser?.first_name || ''}
                onChange={handleInputChange}
                required
                maxLength={30}
              />
            </Form.Group>
            <Form.Group as={Col} md={10} lg={5} className="m-3" controlId="formLastName">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                value={editedUser?.last_name || ''}
                onChange={handleInputChange}
                required
                maxLength={30}
              />
            </Form.Group>
          </Row>
          <Row className="justify-content-between">
            <Form.Group as={Col} md={10} lg={5} className="m-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={editedUser?.email || ''}
                onChange={handleInputChange}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} md={10} lg={5} className="m-3" controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="XXX-XXX-XXXX"
                value={editedUser?.phone_number || ''}
                maxLength={12}
                onChange={(e) => handlePhoneChange(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row className="justify-content-between">
            <Form.Group as={Col} md={10} lg={5} className="m-3" controlId="formBusinessType">
              <Form.Label>Business Type</Form.Label>
              <Form.Control
                type="text"
                name="business_type"
                value={editedUser?.business_type || ''}
                onChange={handleInputChange}
                required
                maxLength={100}
              />
            </Form.Group>
            <Form.Group as={Col} md={10} lg={5} className="m-3" controlId="formBusinessName">
              <Form.Label>Business Name</Form.Label>
              <Form.Control
                type="text"
                name="business_name"
                value={editedUser?.business_name || ''}
                onChange={handleInputChange}
                required
                maxLength={100}
              />
            </Form.Group>
          </Row>
          <Row className="justify-content-center align-items-center">
            <button className={`${style.submitButton} btn btn-primary mt-4`} type="submit">
              Save
            </button>
          </Row>
        </div>
      </form>
      {isLoading ? <OverlayLoader /> : null}
    </div>
  );
};

export default ProfileSettings;
